import React from 'react'
import { Link } from 'gatsby'
import { navigate } from "gatsby"
import { Layout, SEO } from '../../components/common'


const ExplorationComparePage = ({ data, location, pageContext }) => {
    const explorationSlug = 'compare';

    return (
        <Layout>
            <SEO title='Cross-metric Comparisons' description='This analysis allows for you to upload several types of quantified self data, visualize any individual metric, and look for correlations between that metric and others.' />
            <div className="container">
                <article className="content">
                    <h1 className="content-title">Cross-metric Comparisons</h1>
                    <section className="content-body">
                        <p>
                            This analysis allows for you to upload several types of quantified self data,
                            visualize any individual metric, and look for correlations between that metric and others.
                        </p>
                        <p>
                            Additionally, the notebook will look for correlations between ALL of your metrics to find the most powerful insights available.
                        </p>
                        <div>
                            This notebook requires:
                            <ul>
                                <li>Upload of data via a CSV in a particular format (your data won't be visible to anybody but yourself)</li>
                                <li>Data is organized by metrics, each metric can have 1 measurement for the day (or no measurement at all)</li>
                                <li>Supported measurement formats: booleans, integers, floats</li>
                                <li>This analysis works best if you have at least 30 days worth of data for several metrics. However, if there are not enough insights drawn you are able to increase the correlation sensitivity.</li>
                            </ul>
                        </div>
                        <p>
                            <div>Want to check it out?</div>
                            <div>It's free to use right now, please just fill out the survey at the end so that I can keep on improving the experience.</div>
                            <button onClick={(e) => {
                                e.preventDefault();
                                const visUrl = `/vis/${explorationSlug}/demo/`;
                                navigate(visUrl);
                            }}>See the notebook here</button>
                        </p>

                    </section>

                    {/*}
                    <section className="content-body">
                        <h3>Have a license key?</h3>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            const code = document.getElementById('explorationKey').value;
                            if (code){
                                // go to that specific vis page
                                const visUrl = `/vis/${explorationSlug}/${code}/`;
                                navigate(visUrl);
                            }
                        }}>
                            <input id="explorationKey" type="text" placeholder="Enter License Key"></input>
                        </form>
                    </section>
                */}
                </article>
            </div>
        </Layout>
    )
}

export default ExplorationComparePage
